import { render, staticRenderFns } from "./NewsletterSection.vue?vue&type=template&id=89360a6c&lang=pug"
import script from "./NewsletterSection.vue?vue&type=script&lang=js"
export * from "./NewsletterSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsletterForm: require('/opt/build/repo/components/NewsletterForm.vue').default})
