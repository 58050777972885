
import countries from '~/assets/data/countries_all'
export default {
  name: 'SubscriptionForm',
  props: ['channel', 'campaign', 'campaign_description', 'additional_fields', 'formClass', 'formCheckClass', 'buttonText', 'buttonClass', 'redirectPage', 'isDark', 'hideOnSubmit', 'couponCode'],
  data() {
    return {
      formData: {},
      isLoading: false,
      submitSuccess: false,
      submitError: false,
      hideForm: false,
      focused: false,
      countries,
      subscriptionForm: {
        first_name: {
          en: 'First Name',
          it: 'Nome',
          de: 'Nachname'
        },
        last_name: {
          en: 'Last Name',
          it: 'Cognome',
          de: 'Vorname'
        },
        company: {
          en: 'Company',
          it: 'Azienda',
          de: 'Gesellschaft'
        },
        role: {
          en: 'Role',
          it: 'Ruolo',
          de: 'Unternehmensrolle'
        },
        website: {
          en: 'Website',
          it: 'Sito Web',
          de: 'Webseite'
        },
        email: {
          en: 'Email',
          it: 'Email',
          de: 'E-Mail'
        },
        phone: {
          en: 'Phone',
          it: 'Numero di telefono',
          de: 'Telefon'
        },
        country: {
          en: 'Country',
          it: 'Paese',
          de: 'Land'
        },
        yes: {
          en: `Yes`,
          it: `Si`,
          de: 'Ya'
        },
        no: {
          en: `No`,
          it: `No`,
          de: 'Nein'
        },
        both: {
          en: `Both`,
          it: `Entrambi`,
          de: 'Beide'
        },
        business: {
          en: `Business`,
          it: `Azienda`,
          de: 'Business'
        },
        consumer: {
          en: `Consumer`,
          it: `Privato`,
          de: 'Kunde'
        },
        account_type: {
          en: 'Customer Type',
          it: `Tipologia cliente`,
          de: 'Kunde Typ'
        },
        notes: {
          en: 'Activity Sector',
          it: `Settore di attività`,
          de: 'Tätigkeit Sektor'
        },
        air_purifier_experience: {
          en: `Have you ever used an air purifier?`,
          it: `Hai mai acquistato o utilizzato un purificatore d’aria?`,
          de: 'Haben Sie schon einmal einen Luftreiniger benutzt?'
        },
        products_interest: {
          en: `Which of our purifiers are you most interested in?`,
          it: `A quale dei nostri purificatori sei maggiormente interessato?`,
          de: 'Welcher unserer Luftreiniger interessiert Sie am meisten?'
        },
        vitesy_knowledge: {
          en: `Did  you already know Vitesy?`,
          it: `Conoscevi già Vitesy?`,
          de: 'Kannten Sie Vitesy bereits?'
        },
      }

    }
  },
  computed:{
    formId() {
      return this.campaign.replace(/\s+/g, '-').toLowerCase()
    },
    currentLocale(){
      return this.$i18n.locale
    },
    storeCountry() {
      return this.$store.getters.country
    },
    hasSuccessMessageSlot() {
      return !!this.$slots.successMessage
    },
    formCountries() {
      return this.countries.map(country => {
        return { value: country.code, label: country.name[this.currentLocale] }
      })
    }
  },
  mounted(){
    this.initForm(false)
    if (this.additional_fields && this.additional_fields.includes('country_it')) { this.formData.country = 'IT' }
    if (this.additional_fields && this.additional_fields.includes('accounttype_business')) { this.formData.account_type = 'Business' }
    if (this.storeCountry) {
      this.formData.country = this.storeCountry.code
    }
  },
  methods: {
    initForm(reset){
      this.formData = {
        campaign: this.campaign,
        campaign_description: this.campaign_description || false,
        channel_group: 'Services',
        channel: this.channel,
        origin: this.origin || 'website',
        policies: { privacy: true, newsletter: true, direct_marketing: true }
      }
      if (reset) {
        this.$formulate.resetValidation(`${this.formId}-form`)
      }
    },
    async submitForm() {
      this.isLoading = true
      this.submitSuccess = false
      this.submitError = false

      const data = {...this.formData, policies: Array.isArray(this.formData.policies) ? this.formData.policies[0] : this.formData.policies}
      const response = await this.sendToGateway(data)
      if (!response || response?.accountId?.error || response?.contactId?.error) {
        this.submitError = true
      } else {
        this.trackFormSubmission()
        if (this.redirectPage) {
          window.location.href = `${this.redirectPage}?email=${this.formData.email || ''}&name=${this.formData.first_name || ''}`
        }
        setTimeout(()=>{
          this.submitSuccess = true
          this.hideOnSubmit ? this.hideForm = true : ''
          this.$store.dispatch('setCustomerEmail',this.formData.email)
          this.couponCode ? this.$store.dispatch('applyCoupon', { couponCode: this.couponCode }) : ''
          this.initForm(true)
        }, 1500)
      }
      this.isLoading = false
    },
    async sendToGateway (data) {
      try {
        const response = await fetch(
          'https://gwapi.vitesy.com/api/crm/customer/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          }
        )
        return await response.json()
      } catch(err){
        return false
      }
    },
    trackFormSubmission(){
      const eventAttributes = {
        form_name: `${this.campaign} Form`,
        form_category: 'Subscription Form',
        form_url: this.$route.fullPath,
        user_data: {
          user_email: this.formData.email,
          user_name: this.formData.name,
          user_company: this.formData.company,
          user_country: this.formData.country,
          user_category: this.formData.company && this.formData.company.length ? 'Business' : 'Customer',
          // userId: this.formData.user_id,
          // request_type: this.formData.requestType,
          // device_os: this.formData.device_os,
          // device_model: this.formData.device_model,
          // app_type: this.formData.app_type,
          // app_version: this.formData.app_version,
        }
      }
      this.$gtm.push({ event: 'generate_lead', ...eventAttributes })
    }
  }
}
