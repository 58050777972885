
export default {
  name: 'CartController',
  data() {
    return {
      couponCode: '',
      cartItems: [],
      cartItemsUpdated: false
    }
  },
  computed: {
    order(){
      return JSON.parse(JSON.stringify(this.$store.getters.order))
    },
    lineItems(){
      return this.order?.line_items?.filter(i => i.item_type === 'skus' || i.item_type === 'bundles')
    },
    lineItemsPromotions(){
      return this.order?.line_items?.filter(i => i.item_type === 'promotion')
    },
    lineItemsCount() {
      let count = 0
      this.lineItems?.forEach(i => { count = count + i.quantity })
      return count
    },
    market(){
      return this.$store.getters.market
    },
    country(){
      return this.$store.getters.country
    }
  },
  watch: {
    lineItemsCount(){
      this.cartItemsUpdated = true
      setTimeout(()=>{
        this.cartItemsUpdated = false
      }, 300)
    },
    order(){
      this.checkDiscount()
    }
  },
  async mounted() {
    await this.$store.dispatch('getLocation')
    await this.checkDiscount()

    if (this.$route.query.orderId) {
      const o = await this.$store.dispatch('getOrder', this.$route.query.orderId)
      console.log(o)
    }

    const utmParams = Object.keys(this.$route.query)
      .filter(key => key.startsWith('utm_') || key.startsWith('rfsn'))
      .reduce((obj, key) => {
        obj[key] = this.$route.query[key];
        return obj;
      }, {});
    if (Object.keys(utmParams).length > 0) {
      this.$store.commit('setUtmParams', utmParams);
    }

  },
  methods: {
    checkDiscount() {
      this.couponCode = this.order.coupon_code || ''

      setTimeout(() => {
        if (this.$route.query.email) {
          this.$store.dispatch('setCustomerEmail', this.$route.query.email)
        }
        if (this.$route.query.discount && this.$route.query.discount !== this.couponCode) {
          this.couponCode = this.$route.query.discount
          this.applyCoupon(this.couponCode)
        }
        if (this.$route.query.referral && this.$route.query.referral !== this.couponCode) {
          this.couponCode = this.$route.query.referral
          this.applyCoupon(this.couponCode)
        }
      }, 3000)
    },
    async applyCoupon(couponCode){
      if (couponCode) {
        const toastNotification = {
          message: this.$t('shop.couponToast',{code: couponCode}),
          duration: 7000
        }
        await this.$store.dispatch('applyCoupon', { couponCode, toastNotification })
      }
    },
    async updateOrder(attributes){
      await this.$store.dispatch('updateOrder', attributes)
    }
  }
}
