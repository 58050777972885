export default function (context) {
  const utmParams = Object.keys(context.query)
    .filter(key => key.startsWith('utm_') || key.startsWith('rfsn'))
    .reduce((obj, key) => {
      obj[key] = context.query[key];
      return obj;
    }, {});

  if (Object.keys(utmParams).length > 0) {
    context.store.commit('setUtmParams', utmParams);
  }
}